import React from 'react';
import Layout from '../components/layout';
import {useTranslation} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import SEO from "../components/seo";

const Press = () => {
    const {t} = useTranslation();

    return (
        <Layout pageID={'contact'}>
            <SEO title="Press" />
            <section className="top">
                <h1>{t('press')}</h1>
            </section>
            <section className="content">
                <ul className="press">
                    <li>
                        <span>{t('haaretz.saison')}</span>
                        <span> - </span>
                        <a href="https://www.haaretz.co.il/food/drinks/.premium-MAGAZINE-1.10689852" rel={"noopener noreferrer"} target={'_blank'}>https://www.haaretz.co.il/food/drinks/.premium-MAGAZINE-1.10689852</a>
                    </li>
                    <li>
                        <span>{t('haaretz.kolsch')}</span>
                        <span> - </span>
                        <a href="https://www.haaretz.co.il/food/drinks/MAGAZINE-1.10708386" rel={"noopener noreferrer"} target={'_blank'}>https://www.haaretz.co.il/food/drinks/MAGAZINE-1.10708386</a>
                    </li>
                    <li>
                        <span>{t('doug.saison')}</span>
                        <span> - </span>
                        <a href="https://israelbrewsandviews.blogspot.com/2021/01/nomads-weiser-von-kolsch.html" rel={"noopener noreferrer"} target={'_blank'}>https://israelbrewsandviews.blogspot.com/2021/01/nomads-weiser-von-kolsch.html</a>
                    </li>
                    <li>
                        <span>{t('doug.kolsch')}</span>
                        <span> - </span>
                        <a href="https://israelbrewsandviews.blogspot.com/2021/01/nomads-weiser-von-kolsch.html" rel={"noopener noreferrer"} target={'_blank'}>https://israelbrewsandviews.blogspot.com/2021/01/nomads-weiser-von-kolsch.html</a>
                    </li>
                    <li>
                        <span>{t('doogri.saison')}</span>
                        <span> - </span>
                        <a href="https://www.youtube.com/watch?v=c-5YftN9Id4" rel={"noopener noreferrer"} target={'_blank'}>https://www.youtube.com/watch?v=c-5YftN9Id4</a>
                    </li>
                    <li>
                        <span>{t('doogri.kolsch')}</span>
                        <span> - </span>
                        <a href="https://www.youtube.com/watch?v=XqOISjcEaaE" rel={"noopener noreferrer"} target={'_blank'}>https://www.youtube.com/watch?v=XqOISjcEaaE</a>
                    </li>
                </ul>
            </section>
        </Layout>
    )
}

export default Press;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "press"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
